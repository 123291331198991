import './App.css';
import React from "react";

function App() {
    const items = [
        ['The American farmer is outstanding in his field.', ':)'],
        ['I could tell a joke about pizza,', 'but it\'s a little cheesy.'],
        ['Don\'t trust atoms.', 'They make up everything!'],
        ['I like telling Dad jokes.', 'Sometimes he even laughs!'],
        ['When does a joke become a dad joke?', 'When it becomes apparent.'],
        ['Wanna hear a joke about paper?', 'Never mind. It\'s tearable.'],
        ['Where do baby cats learn to swim?', 'The kitty pool.'],
        ['I made a pencil with two erasers.', 'It was pointless.'],
        ['Why are elevator jokes so good?', 'They work on so many levels.'],
        ['Why are skeletons so calm?', 'Because nothing gets under their skin.'],
        ['Over what was the penguin civil war fought?', 'Just-ice']
    ];

    const item = items[Math.floor(Math.random() * items.length)];
    const DelayComponent = () => {
        const [show, setShow] = React.useState(false)

        React.useEffect(() => {
            setTimeout(() => {
                setShow(true)
            }, 2000)
        }, [show])

        if (!show) return <div><div>{item[0]}</div><div>&nbsp;</div></div>
        return <div><div>{item[0]}</div><div>{item[1]}</div></div>
    }

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    <DelayComponent/>
                </p>
            </header>
        </div>
    );
}

export default App;
